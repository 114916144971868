@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap" rel="stylesheet);
body{
	font-family: 'NexaBold';
	overflow: inherit !important;
}
/*img:focus,img:hover {
	cursor: pointer;
  	transform: scale(0.95);
}*/

.imageHover:focus,.imageHover:hover {
	cursor: pointer;
  	-webkit-transform: scale(0.95);
  	        transform: scale(0.95);
}


@-webkit-keyframes fadein {
  from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadein {
  from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadeout {
  from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
    to   { opacity: 0; }
}
