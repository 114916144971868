/*img:focus,img:hover {
	cursor: pointer;
  	transform: scale(0.95);
}*/

.imageHover:focus,.imageHover:hover {
	cursor: pointer;
  	transform: scale(0.95);
}


@keyframes fadein {
  from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
    to   { opacity: 0; }
}